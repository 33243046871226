import React from 'react'
import Slider from "react-slick";
import lodash from "lodash";

type Props = {}

const Footer = (props: Props) => {
    const settings = {
        infinite: true,
        autoplay: true,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        speed: 9000,
        autoplaySpeed: 0,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    };
    return (
        <div className='w-full bg-[#2723FF] mt-5'>
            <div className='container pt-10'>
                <div className='w-[90%] mx-auto py-0 relative'>
                    <div className='flex pb-5'>
                        <div className='w-full lg:w-1/2 text-white'>
                            <h1 className='font-bold text-3xl'>CHOUPETTE</h1>
                            <p className='font-semibold'>opyright ©2024 BODEN</p>
                            <p className='font-semibold'>Legal Disclaimer: CHOUPETTE is a meme coin with no intrinsic value
                                or expectation of financial return. CHOUPETTE is completely useless
                                and for entertainment purposes only. When you purchase CHOUPETTE, you
                                are agreeing that you have seen this disclaimer
                            </p>
                        </div>
                        <div className='w-1/2 hidden lg:block'>

                        </div>
                    </div>
                    <div className='w-full'>
                        <Slider {...settings} className='max-h-[420px]'>
                            {lodash.range(1, 12).map((val: number) => (
                                <div className=" px-4 py-3 " key={val}>
                                    <h1 className='text-black text-5xl font-black '>$CHOUPETTE</h1>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className='w-full flex justify-center items-center'>
                        <img src="assets/chou-logo.png" className='h-[100px]' alt="" />
                        <h1 className='font-bold text-3xl text-white'>BY Characterizator</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer