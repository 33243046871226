import React from 'react'
import Slider from "react-slick";
import lodash from "lodash";
type Props = {}

const Memes = (props: Props) => {
    const settings = {
        infinite: true,
        autoplay: true,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        speed: 9000,
        autoplaySpeed: 0,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    };
    const setting2s = {
        infinite: true,
        autoplay: true,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        speed: 9000,
        autoplaySpeed: 0,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        rtl: true
    };

    return (
        <div className='w-full '>
            <div className='container'>
                <div className='w-full'>
                    <div className='w-full text-center'>
                        <h1 className='text-6xl font-bold mb-6 mt-16'>MEMES</h1>
                    </div>
                    <div className='w-full flex flex-col gap-7'>
                        <div className='w-full'>
                            <Slider {...settings} className='max-h-[420px]'>
                                {lodash.range(1, 5).map((val: number) => (
                                    <div className="w-[300px] px-1">
                                        <img
                                            className="rounded-3xl w-[300px] h-[300px]"
                                            loading="lazy"
                                            alt=""
                                            src={`/assets/CHOUPETTE-${val}.png`}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className='w-full'>
                            <Slider {...setting2s} className='max-h-[420px]'>
                                {lodash.range(1, 5).map((val: number) => (
                                    <div className="w-[300px] px-1">
                                        <img
                                            className="rounded-3xl w-[300px] h-[300px]"
                                            loading="lazy"
                                            alt=""
                                            src={`/assets/CHOUPETTE-${val}.png`}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className='w-full'>
                            <Slider {...settings} className='max-h-[420px]'>
                                {lodash.range(1, 5).map((val: number) => (
                                    <div className="w-[300px] px-1">
                                        <img
                                            className="rounded-3xl w-[300px] h-[300px]"
                                            loading="lazy"
                                            alt=""
                                            src={`/assets/CHOUPETTE-${val}.png`}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Memes